import React, { useEffect, useContext, useState } from "react";
import { produtos } from "./JsonProdutosDry";
import style from "./Produtos.module.css";
import Modal from "../Modal/Modal";
import { QtdContext } from "../../context/qtdContext";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ButtonsModal from "../Modal/ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
const ProdutosSuhKorma = ({ amount }) => {
  // const “ = JSON.parse(localStorage.getItem("products"));
  const { serverProd, produtosSelecionados, opt, tabs, setTabs, setModalCart, setProdutosSelecionados } = useContext(QtdContext);
  const navigate = useNavigate()

  const drydey = serverProd.filter((prod) => prod.kategory.includes("сухой корм"))
  // console.log(drydey)

  const removeItem = async (itemId) => {
    const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
    setProdutosSelecionados(prodRemove)
    localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

  }
  const changeItensCart = (id) => {
    const produtos = serverProd

    const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +id)
    const produtoDados = produtos.find((produto) => produto.id == +id);



    const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
    const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +id);
    const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
    // console.log(novosProdutos, produto)
    setProdutosSelecionados(novosProdutos);
    localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

    // console.log(produtosNaoSelecionados, produtosSelecionados)
    // setModal(false);
    // console.log('novosProdutos')
  }
  const GoInfo = (imageId, name) => {
    navigate(`/prodinfo/${imageId}`, {
      state: {
        serverProd:serverProd,

        produtosSelecionados:produtosSelecionados,
        name: name,
        imageId: imageId,
        loca: "/suhkorm"

      }
    });
  };
  // let tt = photo[0]
  // if(!photo){
  //   tt = ''
  // }

  return (
    <>
      <br />

 ]
      <div className={style.produtos_container}>
        {!opt.auth && drydey.map(
          ({ photo, name, price, id, forwho,scladqty }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const cart = produtosSelecionados.find((produto) => produto.id == +id);
            let cartb = false
            if (cart) {
              cartb = true
            }

            return index <= amount && (
              <div key={id} id={id}  className={style.cards_produtos}>
                {/* <img id={id} className={style.img} src={tt} /> */}
                <div onClick={() => GoInfo(id, name)}className={style.imgcont}>
                     <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt="Example Image"
                      height="100%"
                      effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                    />
                </div>
             
                <p onClick={() => GoInfo(id, name)}id={id}>{name}</p>
                {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                <div onClick={() => GoInfo(id, name)}className={style.vbadgecont}>
                  {/* {(name == 'Печеньки говяжьи с овощами 50г'
                    || name == 'Вымя говяжье 50г'
                    || name == "Бычий Корень 50г"
                    || name == "Лёгкое говяжье 50г"
                    || name == "Жилки Говяжьи 50г"
                    || name == "Рубец Кубики 50г") && (
                      <div href="#" className={style.vbadge} style={{ 'background': '#04b31b', 'color': 'black' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                  {(name == 'Почки Говяжьи 50г' || name == 'Ухо Внутреннее Говяжье 50г' || name == 'Трахея Ломтики 50г') && (

                    <div href="#" className={style.vbadge}>
                      NEW
                      <span></span>
                    </div>
                  )} */}
                  {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                      Распродано!
                      <span></span>
                    </div>}



                  {/* <div href="#" className={style.vbadge} style={{ 'background': '#20b6e8' }}>
                    Натуральный
                    <span></span>
                  </div> */}
                </div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb &&Number(scladqty) > 0 &&  (<>


                        <ButtonsModal id={id} />
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb &&Number(scladqty) > 0 &&  (<>
                        <Button  width={'100px'} text="В Корзину" style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                        <Button  width={'100px'}text="Удалить из Корзины " style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
              </div>
            )
          }
        )}
      
      </div>

    </>
  );
};

export default ProdutosSuhKorma;
