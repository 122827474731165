import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewProdForm.module.css'
// import Title from "../../Title";
import Button from '../../../../Button/Button'
import $api from "../../../../../http/index.ts";

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
// import { FaEdit } from "react-icons/fa";
// import ModalEdit from '../Components/Modal/Modal';
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF"];
const NewProdForm = () => {
    const { sclad, setSclad, modalEdit, setModalEdit, setServerProd } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const handleChange = (file) => {
        setFile(file);
        setFileURL(URL.createObjectURL(file))
        // console.log(file)
    };
    const [newProd, setNewProd] = useState({
        name: "",
        kategory: '',
        scladqty: '',
        price: '',
        shcode: '',
        forwho:'',
        about: '',
        photo: '',
        recomendation: ''
    })
    const [err, setErr] = useState({})
    const create = async (e) => {
        try {
            e.preventDefault();

            let t = {
                name: false,
                kategory: false,
                scladqty: false,
                price: false,
                shcode: false,
                about: false,
                photo: false,
                recomendation: false
            }

            if (!newProd.name) {
                t.name = 'Наименование обязательно к заполнению!'
            }
            if (!newProd.kategory) {
                t.kategory = 'Категория обязательна к выбору!'
            }
            if (!newProd.scladqty) {
                t.scladqty = 'Остаток на складе обязательн к заполнению!'
            }
            if (!newProd.price) {
                t.price = 'Розничная цена обязательна к заполнению!'
            }
            if (!newProd.about) {
                t.about = 'Описание обязательно к заполнению!'
            }
            if (!newProd.shcode) {
                t.shcode = 'ШК обязательно к заполнению!'
            }
            // if (!file) {
            //     t.photo = 'photo обязательно к заполнению!'
            // }
            if (!newProd.recomendation) {
                t.recomendation = 'recomendation обязательно к заполнению!'
            }
            // console.log(t)
            setErr(t)
            if (newProd.name &&
                newProd.kategory &&
                newProd.scladqty &&
                newProd.price &&
                newProd.about &&
                newProd.shcode &&
                // file &&
                newProd.recomendation &&
                !t.name &&
                !t.kategory &&
                !t.scladqty &&
                !t.price &&
                !t.about &&
                !t.shcode &&
                !t.photo &&
                !t.recomendation
            ) {

                const formData = new FormData()
                
                setLoading(true)
                const newProd5 = await $api.post(`${BACKURL}/api/utbteam/createproduct`,
                    { ...newProd }
                )
                let prelist = []

                // for (let i = 0; i < newProd5.data.length; i++) {
                //     prelist[i] = ({
                //         shcode: newProd5.data[i].shcode,
                //         kategory: newProd5.data[i].kategory,
                //         name: newProd5.data[i].name,
                //         brakqty: newProd5.data[i].brakqty,

                //         mnt: newProd5.data[i].mnt,
                //         price: Number(newProd5.data[i].price),
                //         optprice: Number(newProd5.data[i].optprice),
                //         id: Number(newProd5.data[i].id),
                //         quantidade: 1,
                //         vipprice: newProd5.data[i].vipprice.map(pf => JSON.parse(pf)),
                //         // planedqty:prod.data[i].planedqty,
                //         photo: newProd5.data[i].photo,
                //         text: newProd5.data[i].about,
                //         recomendation: newProd5.data[i].recomendation,
                //         scladqty: newProd5.data[i].scladqty,
                //     })

                // }
                // setSclad(prelist)
                // setServerProd(prelist);

                setNewProd({
                    name: "",
                    kategory: '',
                    scladqty: '',
                    price: '',
                    shcode: '',
                    about: '',
                    photo: '',
                    forwho:'',
                    recomendation: ''
                })
                setLoading(false)

                alert('Готово!')
            }

            // console.log(newProd)
        } catch (e) {
            setLoading(false)

            // console.log(e)
        }

    }


    return (<>
        <br />
        <div className={style.container_form}>
            <form className={style.form}>
                {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                {fileURL && <img src={fileURL}></img>} */}
                <label for="name">Введите адрес фото:</label>
                <input type="text" id="name" name="name" onChange={(event) => {
                    setNewProd({ ...newProd, photo: event.target.value });
                }} />
                <label for="name">Введите Наименование:</label>
                <input type="text" id="name" name="name" onChange={(event) => {
                    setNewProd({ ...newProd, name: event.target.value });
                }} />
                {err.name && (
                    <p style={{ color: "red" }}>{err.name}</p>
                )}
                <br></br>
                <label for="pet-select">Выберите Категорию:</label>

                <select name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, kategory: event.target.value });
                }}>
                    <option value="">--Выберите Категорию--</option>
                    <option value="фарш">фарш</option>
                    <option value="мясные консервы для кошек">мясные консервы для кошек</option>
                    <option value="мясные консервы для собак">мясные консервы для собак</option>
                    <option value="сушенная продукция для собак">сушенная продукция для собак</option>
                    <option value="сушенная продукция для кошек">сушенная продукция для кошек</option>
                    <option value="отдельная мороженная продукция">отдельная мороженная продукция</option>
                    <option value="сухой корм">сухой корм</option>
                    <option value="рацион">рацион</option>
                    <option value="Каталог товаров">Каталог товаров</option>
                    <option value="Амуниция">Амуниция</option>
                </select>

                {err.kategory && (
                    <p style={{ color: "red" }}>{err.kategory}</p>
                )}
                  <br></br>
                <label for="pet-select">Для кого:</label>

                <select name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, forwho: event.target.value });
                }}>
                    <option value="">--Выберите Категорию--</option>
                    <option value="Для кошек">Для кошек</option>
                    <option value="Для собак">Для собак</option>
                    <option value="Для грызунов">Для грызунов</option>
                </select>

                {err.kategory && (
                    <p style={{ color: "red" }}>{err.kategory}</p>
                )}
                <br></br>
                <label for="scladqty">Введите остаток на складе:</label>
                <input type="тгьиук" id="scladqty" name="scladqty" onChange={(event) => {
                    setNewProd({ ...newProd, scladqty: event.target.value });
                }}
                />
                {err.scladqty && (
                    <p style={{ color: "red" }}>{err.scladqty}</p>
                )}
                <br></br>

                <label for="price">Введите розничную цену:</label>
                <input type="text" id="price" name="price" onChange={(event) => {
                    setNewProd({ ...newProd, price: event.target.value });
                }} />
                {err.price && (
                    <p style={{ color: "red" }}>{err.price}</p>
                )}
                <br></br>

                <label for="text">Введите рекомендации÷ товара:</label>
                <textarea type="text" style={{ height: '200px' }} id="recomendation" name="text" onChange={(event) => {
                    setNewProd({ ...newProd, recomendation: event.target.value });
                }} />
                {err.recomendation && (
                    <p style={{ color: "red" }}>{err.recomendation}</p>
                )}
                <br></br>
                <label for="text">Введите Описание для нового товара:</label>
                <textarea type="text" style={{ height: '200px' }} id="about" name="text" onChange={(event) => {
                    setNewProd({ ...newProd, about: event.target.value });
                }} />
                {err.about && (
                    <p style={{ color: "red" }}>{err.about}</p>
                )}
                <br></br>
                <label for="text">Введите ШК:</label>
                <textarea type="text" style={{ height: '200px' }} id="shcode" name="text" onChange={(event) => {
                    setNewProd({ ...newProd, shcode: event.target.value });
                }} />
                {err.shcode && (
                    <p style={{ color: "red" }}>{err.shcode}</p>
                )}
                <br></br>
                <br></br>

                <Button text='Создать!' onClick={create} />
            </form>
        </div>
        {loading && (
            <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )}
    </>
    )
}
export default NewProdForm